<!-- eslint-disable -->
<template>
  <div>
    <slot name="label">
      <label v-if="label" :for="id" class="form-label">
        {{ label }}
      </label>
    </slot>

    <div class="input-group relative">
      <div
        class="input-group-text"
        :style="{ 'background-color': modelValue, 'color': textColor }"
      >
        <i class="fal fa-palette fa-lg" />
      </div>

      <input
        :id="id"
        :value="modelValue"
        type="text"
        :class="[{ 'border-theme-6': hasErrors }, { 'cursor-picker': isInputReadonly }]"
        class="form-control w-full"
        :readonly="isInputReadonly"
        aria-describedby="input-group-text"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="onFocus"
      />

      <div
        v-if="isColorPickerOpen"
        class="absolute w-screen h-screen"
        tabIndex="-1"
        @click="onBlur"
      >
        <div
          class="chk-color absolute inset-x-0"
          style="top: 2.6rem; width: 276px;"
          @click.stop="onFocus"
        >
          <ColorPicker
            v-model:gradientColor="gradientColor"
            v-model:pureColor="pureColor"
            pickerType="chrome"
            disableHistory
            disableAlpha
            isWidget
            lang="En"
            format="hex"
            @update:pureColor="onUpdateColor"
          />
        </div>
      </div>
    </div>

    <VErrors :errors="errors" />
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
// Mixins
import InputMixin from "@/mixins/InputMixin";
// Composables
import useColor from "@/composables/useColor";

export default {
  components: {
    ColorPicker
  },
  mixins: [InputMixin],
  props: {
    modelValue: {
      type: String,
      default: "#000000"
    },
    isInputReadonly: {
      type: Boolean,
      default: true
    }
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    // Composables
    const { getContrastingColor } = useColor();

    // Computed
    const textColor = computed(() => getContrastingColor(props.modelValue));

    // Data
    const isColorPickerOpen = ref(false);
    const gradientColor = ref("");
    const pureColor = ref("");

    // Methods
    const onFocus = () => {
      if (props.readonly || props.disabled) {
        return;
      }

      isColorPickerOpen.value = true;
    };

    const onUpdateColor = value => {
      if (value === props.modelValue) {
        return;
      }

      context.emit("update:modelValue", value);
    };

    const onBlur = () => {
      isColorPickerOpen.value = false;
    };

    // Watch
    watch(
      () => props.modelValue,
      () => {
        pureColor.value = props.modelValue;
      }
    );

    return {
      onBlur,
      pureColor,
      gradientColor,
      onFocus,
      onUpdateColor,
      isColorPickerOpen,
      textColor
    };
  }
};
</script>

<style scoped lang="scss">
.chk-color {
  :deep(.vc-colorpicker) {
    @apply pb-0;

    :deep(.vc-chrome-colorPicker-body) {
      :deep(.vc-colorPicker__record) {
        @apply hidden;
      }
    }
  }
}
</style>
