<template>
  <div>
    <slot :id="id" name="label">
      <label v-if="label" :for="id" class="form-label">
        {{ label }}
      </label>
    </slot>

    <div class="input-group relative w-full">
      <div class="input-group-text cursor-pointer dropdown">
        <i class="fal fa-tag fa-lg dropdown-toggle" />

        <div id="tag-dropdown" class="dropdown-menu w-40">
          <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
            <div
              v-for="(option, index) in selectedOptions"
              :key="index"
              class="flex items-center block p-2 cursor-pointer transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              @click="onClickRedirect(option.id)"
            >
              {{ option.name }}
            </div>
          </div>
        </div>
      </div>

      <TomSelect
        :model-value="modelValue"
        class="w-full"
        :multiple="true"
        :disabled="readonly"
        :options="{
          create: true,
          createOnBlur: true,
          placeholder: placeholder,
          allowEmptyOption: allowEmptyOption,
          options: options,
          labelField: labelField,
          valueField: valueField,
          plugins: plugins
        }"
        :class="[inputClass, { 'has-errors': hasErrors }]"
        @update:modelValue="onUpdate"
      />
    </div>

    <VErrors :errors="errors" />
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
// Composables
import useOptions from "@/composables/useOptions";
// Mixins
import InputMixin from "@/mixins/InputMixin";

export default {
  mixins: [InputMixin],
  props: {
    allowEmptyOption: {
      type: Boolean,
      default: false
    },
    labelField: {
      type: String,
      default: "name"
    },
    valueField: {
      type: String,
      default: "name"
    }
  },
  setup(props, context) {
    // Misc
    const router = useRouter();

    // Data
    const options = ref([]);

    // Computed
    const plugins = computed(() => {
      if (props.disabled || props.readonly) {
        return {};
      }

      return {
        clear_button: {}
      };
    });

    const selectedOptions = computed(() => {
      return options.value?.filter(option => {
        return props.modelValue?.includes(option.name);
      });
    });

    // Composables
    const { getTags } = useOptions();

    // Methods
    const onUpdate = e => {
      setOptions(e);
      context.emit("update:modelValue", e);
    };

    const setOptions = value => {
      const newOptions = value.filter(x => {
        return !options.value.some(option => option.code === x);
      });

      options.value = [...options.value, ...newOptions];
    };

    const onClickRedirect = id => {
      hideDropdown();

      router.push({
        name: "tag",
        params: { id }
      });
    };

    const hideDropdown = () => {
      cash("tag-dropdown").dropdown("hide");
    };

    // Lifecycle Hooks
    onMounted(async () => {
      options.value = await getTags();
    });

    return {
      selectedOptions,
      options,
      plugins,
      onUpdate,
      onClickRedirect
    };
  }
};
</script>

<style scoped lang="scss">
.has-errors ~ :deep(.tom-select .ts-input) {
  @apply border-theme-6;
}
</style>
